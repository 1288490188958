import dayjs from "dayjs";
import React, { cloneElement, useMemo } from "react";
import {
  ReferenceInput,
  Pagination,
  ArrayField,
  ReferenceField,
  FunctionField,
  List,
  Filter,
  NumberInput,
  SelectInput,
  TextInput,
  Show,
  Datagrid,
  TextField,
  ShowButton,
  NumberField,
  SelectField,
  Link,
  Button,
  AutocompleteInput,
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import { saveAs } from "file-saver";
import LinkIcon from "@material-ui/icons/Link";
import UpdateIcon from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ApprovalIcon from "@material-ui/icons/DoneAll";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Renderer } from "xlsx-renderer";
import ticketsTemplate from "../assets/template-tickets.xlsx";
import TravelersField from "../components/Fields/TravelersField";
import { TheTreepDateField } from "../components/Fields/TheTreepDateField";
import { operationCategories } from "./operations";
import RefreshTicketsButton from "../components/Buttons/RefreshTicketsButton";
import UpdateStatusIssuedTravelButton from "../components/Buttons/UpdateStatusIssuedTravelButton";
import ApproveTicketsButton from "../components/Buttons/ApproveTicketsButton";
import CancelTicketsButton from "../components/Buttons/CancelTicketsButton";
import DuffelDetailsButton from "../components/Buttons/DuffelDetailsButton";
import PNRDetailsButton from "../components/Buttons/PNRDetailsButton";
import PAODetailsButton from "../components/Buttons/PAODetailsButton";
import RailEuropeDetailsButton from "../components/Buttons/RailEuropeButton";

export const ticketsStatuses = [
  { id: "accepted", name: "Accepté", backgroundColor: "lightgreen" },
  { id: "archived", name: "Archivé", backgroundColor: "lightgrey" },
  { id: "booked", name: "Réservé" },
  { id: "cart", name: "Panier" },
  { id: "cancelled", name: "Annulé", backgroundColor: "lightgrey" },
  { id: "confirmed", name: "Confirmé" },
  { id: "denied", name: "Refusé", backgroundColor: "lightgrey" },
  { id: "done", name: "Terminé" },
  { id: "error", name: "Erreur", backgroundColor: "#ef9a9a" },
  { id: "expired", name: "Expiré", backgroundColor: "lightgrey" },
  { id: "exchanged", name: "Échangé" },
  { id: "issued", name: "Émis (legacy)", backgroundColor: "#C8E6C9" },
  { id: "ticketed", name: "Émis", backgroundColor: "#C8E6C9" },
  { id: "modified", name: "Modifié", backgroundColor: "#C8E6C9" },
  { id: "not_found", name: "Annulé" },
  { id: "paid", name: "Payé" },
  {
    id: "partially_booked",
    name: "Partiellement réservé"
  },
  {
    id: "partially_cancelled",
    name: "Partiellement annulé",
    backgroundColor: "lightgrey"
  },
  {
    id: "partially_confirmed",
    name: "Partiellement confirmé",
    backgroundColor: "#C8E6C9"
  },
  { id: "partially_paid", name: "Partiellement payé" },
  {
    id: "partially_issued",
    name: "Partiellement émis (legacy)",
    backgroundColor: "#C8E6C9"
  },
  {
    id: "partially_ticketed",
    name: "Partiellement émis",
    backgroundColor: "#C8E6C9"
  },
  {
    id: "partially_refunded",
    name: "Partiellement remboursé",
    backgroundColor: "lightgrey"
  },
  { id: "pending_approval", name: "En attente d'approbation" },
  { id: "pending_confirmation", name: "En attente de confirmation" },
  {
    id: "pending_issuing",
    name: "En attente d'émission (legacy)",
    backgroundColor: "#ffcc80"
  },
  {
    id: "pending_ticketing",
    name: "En attente d'émission",
    backgroundColor: "#ffcc80"
  },
  {
    id: "pending_cancel",
    name: "En attente d'annulation",
    backgroundColor: "#ffcc80"
  },
  {
    id: "pending_payment",
    name: "En attente de paiement",
    backgroundColor: "#ffcc80"
  },
  {
    id: "pending_refund",
    name: "En attente de remboursement",
    backgroundColor: "#ffcc80"
  },
  { id: "refunded", name: "Remboursé", backgroundColor: "lightgrey" },
  { id: "removed", name: "Supprimé", backgroundColor: "lightgrey" },
  { id: "unknown", name: "Inconnu" }
];

const TicketFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <TextInput label="PNR" source="pnr" alwaysOn />
    <TextInput label="REF" source="ref" alwaysOn />
    <TextInput label="PAX (nom)" source="pax" alwaysOn />
    <SelectInput source="status" choices={ticketsStatuses} alwaysOn />
    <AutocompleteInput source="type" choices={operationCategories} alwaysOn />
    <SelectInput
      source="tag"
      choices={[
        { id: "simple-outward", name: "simple-outward" },
        { id: "round-trip-outward", name: "round-trip-outward" },
        { id: "simple-return", name: "simple-return" },
        { id: "round-trip-return", name: "round-trip-return" },
        { id: "low-cost", name: "low-cost" },
      ]}
    />
    <ReferenceInput
      label="Entreprise"
      source="customer_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="from" alwaysOn />
    <TextInput source="to" alwaysOn />
  </Filter>
);

const TicketPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

function displayBooker(booker) {
  return `${booker.firstname} ${booker.lastname} <${booker.email}>`;
}

function displayTravelers(travelers) {
  return travelers.map(
    (traveler) =>
      `${traveler.firstname} ${traveler.lastname} <${traveler.email}>`
  );
}

function displayType(type) {
  for (let i = 0; i < operationCategories.length; i++) {
    if (type === operationCategories[i].id) {
      return operationCategories[i].name;
    }
  }
}

function displayStatus(status) {
  for (let i = 0; i < ticketsStatuses.length; i++) {
    if (status === ticketsStatuses[i].id) {
      return ticketsStatuses[i].name;
    }
  }
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={total}
      />
    </TopToolbar>
  );
};

function displayDate(date) {
  if (date == null || date.length < 12) {
    return "";
  }
  const formattedDate = dayjs(date).format("DD/MM/YYYY HH:mm");
  return formattedDate || "";
}

const exporter = async (
  records,
  fetchRelatedRecords,
  dataProvider,
  resource
) => {
  // Get the filters from the URL
  const params = new URLSearchParams(window.location.hash.substr(1));
  const filters = JSON.parse(params.get("filter"));

  // Fetch the total number of records
  const { total } = await dataProvider.getList(resource, {
    pagination: { page: 1, perPage: 1, limit: 1 },
    sort: { field: "id", order: "ASC" },
    filter: filters,
  });

  const totalPages = Math.ceil(total / 100);

  const allRecords = [];

  for (let page = 1; page <= totalPages; page++) {
    // eslint-disable-next-line no-await-in-loop
    const { data, total: pageTotal } = await dataProvider.getList(resource, {
      pagination: { page, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: filters,
    });

    allRecords.push(...data);
  }

  const tickets = await fetchRelatedRecords(
    allRecords,
    "tickets_id",
    "tickets"
  );

  const template = new URL(ticketsTemplate, process.env.REACT_APP_URL).href;
  const data = {
    tickets: allRecords.map((record) => ({
      ...record,
      ticket_travelers: record.ticket_travelers
        .map(
          (traveler) =>
            `${traveler.firstname} ${traveler.lastname} <${traveler.email}>`
        )
        .join("\n"),
      status: ticketsStatuses.find((status) => status.id === record.status)
        ?.name,
      departure: displayDate(record.departure),
      arrival: displayDate(record.arrival),
      ticket_time_limit: displayDate(record.ticket_time_limit),
      creator: `${record.creator?.firstname} ${record.creator?.lastname} <${record.creator?.email}>`,
      company: record.customer.name,
      emission_date: displayDate(record.emission_date),
      type: displayType(record.type),
      carrier: record.carrier?.name || record.carrier?.code || "",
      fareLabel: record.offer?.fare_label,
      fareConditions: record.offer?.fare_conditions,
      cabinLabel: record.offer?.cabin_label,
      // eslint-disable-next-line no-nested-ternary
      tag: record.tag?.split(",").includes("compliant")
        ? "Conforme"
        : record.tag?.split(",").includes("not-compliant")
          ? "Non conforme"
          : "",
      urls: record.urls?.join("\n"),
      recaps: record.ticket_travelers
        .map((ticketTraveler) => ticketTraveler.recap_url)
        ?.join("\n"),
      main_approver: record.main_approver
        ? `${record.main_approver?.firstname ?? ""} ${
            record.main_approver?.lastname ?? ""
          } <${record.main_approver?.email ?? ""}>`
        : "",
      approvalCreatedAt: displayDate(record.approval?.createdAt),
      approvalStatus: record.approval?.status,
      analytics: record.analytics
        ?.map(
          (analytic) => `${analytic.analytic.label}: ${analytic.value ?? ""}`
        )
        .join("\n"),
    })),
  };

  fetch(template)
    .then((response) => response.arrayBuffer())
    .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, data))
    .then((report) => report.xlsx.writeBuffer())
    .then((buffer) =>
      saveAs(new Blob([buffer]), `${Date.now()}_tickets_export.xlsx`)
    )
    // eslint-disable-next-line no-console
    .catch((err) => console.error("Error writing excel export", err));
};

const PostBulkTicketsButtons = (props) => (
  <>
    <CancelTicketsButton label="ANNULER" {...props} />
    <RefreshTicketsButton
      label="Mettre à jour"
      icon={<UpdateIcon />}
      {...props}
    />
    <UpdateStatusIssuedTravelButton
      label="Mettre le statut en émis"
      icon={<ArrowUpward />}
      {...props}
    />
    <ApproveTicketsButton
      label="Approbation"
      icon={<ApprovalIcon />}
      {...props}
    />
  </>
);

const ShowLogButton = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <Button
      label=""
      component={React.forwardRef((props, ref) => (
        <Link {...props} />
      ))}
      to={{
        pathname: `/ticket_log/?filter={"ticketID"%3A"${record.ticketID}"}`,
      }}
    >
      <AccessTimeIcon />
    </Button>
  );
};

const PNRDataButton = ({ record, ...props }) => (
  <PNRDetailsButton record={record.pnr} {...props} />
);

const PAODataButton = ({ record, ...props }) =>
  record?.provider === "sncf" && (
    <PAODetailsButton record={record.id} {...props} />
  );

const DuffelDataButton = ({ record, ...props }) =>
  record?.provider === "duffel" && (
    <DuffelDetailsButton record={record.id} {...props} />
  );

const RailEuropeDataButton = ({ record, ...props }) =>
  record?.provider === "rail-europe" && (
    <RailEuropeDetailsButton record={record.id} {...props} />
  );

export const TicketList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des tickets"
    actions={<ListActions />}
    exporter={exporter}
    sort={{ field: "id", order: "DESC" }}
    rowclick="show"
    filters={<TicketFilter />}
    pagination={<TicketPagination />}
    bulkActionButtons={<PostBulkTicketsButtons />}
  >
    <Datagrid
      rowStyle={(ticket) => {
        if (!ticket || !ticket.status) return {};
        const ticketStatus = ticketsStatuses.find(
          (status) => ticket.status === status.id
        );
        if (ticket.status === ticketStatus?.id) {
          return ticketStatus
            ? { backgroundColor: ticketStatus.backgroundColor }
            : {};
        }
      }}
    >
      <SelectField source="type" choices={operationCategories} />
      <SelectField source="status" choices={ticketsStatuses} />
      <TravelersField source="ticket_travelers" sortable={false} />
      <TextField source="from" sortable={false} />
      <TextField source="to" sortable={false} />
      <TheTreepDateField source="departure" showTime />
      <TheTreepDateField source="ticket_time_limit" showTime />
      <TextField source="pnr" sortable={false} />
      <TextField source="ref" sortable={false} />
      <TextField source="supplier_ref" sortable={false} />
      <TextField source="price" />
      <TextField source="offer.fare_category" />
      <FunctionField
        label="Booker"
        render={(record) =>
          record.creator
            ? `${record.creator.firstname} ${record.creator.lastname}`
            : ""
        }
      />
      <ReferenceField
        label="Entreprise"
        source="customer_id"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TheTreepDateField source="emission_date" showTime />
      <TextField source="provider" />
      <FunctionField
        label="Ebillet"
        render={(record) =>
          record.urls && (
            <>
              {record.urls.map((url) => (
                <a
                  label="ebillet"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={url}
                >
                  <LinkIcon />
                </a>
              ))}
            </>
          )
        }
      />
      <ShowButton label="" />
      {permissions != null && permissions.includes("admin") && (
        <ShowLogButton />
      )}
    </Datagrid>
  </List>
);

const tkRowStyle = (req) => {
  if (req != null && req.response != null && req.response.status !== null) {
    switch (req.response.status) {
    case "denied":
      return { backgroundColor: "#df5d5d" };
    case "accepted":
      return { backgroundColor: "#76f16a" };
    case "escalated":
      return { backgroundColor: "#75c7bc" };
    default:
      return { backgroundColor: "none" };
    }
  }
};

export const TicketShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations générales">
        <NumberField source="id" />
        <TextField source="ticketID" />
        <TextField source="type" />
        <TextField source="status" />
        <TextField source="status_detail" />
        <TextField source="agency" />
        <ReferenceField
          label="Entreprise"
          source="customer_id"
          reference="companies"
          link="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Booker"
          source="creator.uid"
          reference="users"
          link="show"
          allowEmpty
        >
          <TextField source="lastname" />
        </ReferenceField>
        <TheTreepDateField source="emission_date" showTime />
        <TextField source="from" />
        <TextField source="to" />
        <TheTreepDateField source="departure" showTime />
        <TheTreepDateField source="arrival" showTime />
        <TravelersField source="ticket_travelers" detailed />
        <TextField source="price" />
        <TextField source="headsign" />
        <TextField source="pnr" />
        {permissions != null && permissions.includes("admin") && (
          <PNRDataButton />
        )}
        {permissions != null && permissions.includes("dev") && (
          <PAODataButton />
        )}
        {permissions != null && permissions.includes("dev") && (
          <DuffelDataButton />
        )}
        {permissions != null && permissions.includes("dev") && (
          <RailEuropeDataButton />
        )}
        <TextField source="ref" />
        <TextField source="supplier_ref" />
        <TheTreepDateField source="ticket_time_limit" showTime />

        <FunctionField
          label="Ebillets"
          render={(record) =>
            record.urls && (
              <>
                {record.urls.map((url) => (
                  <div>
                    <a
                      label="ebillet"
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {url}
                    </a>
                  </div>
                ))}
              </>
            )
          }
        />
        <TextField source="service" />
        <TextField source="provider" />
        <TextField source="carrier.name" />
        <TextField source="offer.cabin_label" />
        <TextField source="offer.fare_conditions" />
        <TextField source="offer.fare_label" />
        <ArrayField label="Analytics" source="analytics">
          <Datagrid>
            <TextField source="analytic.label" />
            <TextField source="value" />
          </Datagrid>
        </ArrayField>
        <TextField source="treep_id" />
        <TextField source="set_ref" />
        <TextField source="offer_id" />
        <TextField source="booking_id" />
      </Tab>
      <Tab label="Approbation">
        <ArrayField source="approval.requests">
          <Datagrid rowStyle={tkRowStyle}>
            <TheTreepDateField
              label="Date de la demande"
              source="requestedAt"
              showTime
            />
            <FunctionField
              label="Approbateur"
              render={(record) =>
                `${record.approver.firstname} ${record.approver.lastname}`
              }
            />
            <FunctionField
              label="Approbateur de secours"
              render={(record) =>
                record.secondaryApprover
                  ? `${record.secondaryApprover.firstname} ${record.secondaryApprover.lastname}`
                  : "-"
              }
            />
            <TextField label="Message" source="message" />
            <TheTreepDateField
              label="Date de la relance"
              source="reminderedAt"
              showTime
            />
            <TheTreepDateField
              label="Date de la réponse"
              source="answeredAt"
              showTime
            />
            <FunctionField
              label="Statut"
              render={(record) =>
                record.response != null
                  ? record.response.status
                  : "non renseigné"
              }
            />
            <FunctionField
              label="Raison"
              render={(record) =>
                record.response != null
                  ? record.response.reason
                  : "non renseigné"
              }
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
